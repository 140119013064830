<template>
  <b-modal no-fade v-model="dialog.show" size="xl" :id="`modal-mailTemplate-${uniqueId}`"
           :hide-footer="true" centered @close="closeModal"
           no-close-on-backdrop
  >
<!--    <template #modal-title>
     <span v-if="loading !== true && error == null && mailTemplate != null">
         <span v-for="(value, key) in mailTemplate.subject" :key="key">
          <span v-if="value!=null && value!==''">{{ key }}: {{ value }}</span>
        </span>
     </span>

      <span v-if="loading !== true && error == null && mailTemplate != null">
         <span v-for="(value, key) in mailTemplate.body" :key="key">
          <span v-if="value!=null && value!==''">{{ key }}: {{ value }}</span>
        </span>
     </span>
    </template>-->
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <mailTemplate-edit-details v-bind:new-mailTemplate="isNewMailTemplate" v-model="mailTemplate" @input="closeModal"/>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%"
           v-else-if="loading !== true && error != null"
      >
        <b-alert show variant="danger">
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{ $t('operations.loading') }}</b-spinner>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';
import MailTemplateEditDetails from '@/components/partial/mailTemplates/MailTemplateEditDetails';
import MailTemplate from '@/model/mailTemplate/MailTemplate';

export default {
  name: 'mailTemplateModal',
  components: { MailTemplateEditDetails },
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewMailTemplate: false,
    mailTemplate: {}
  }),
  watch: {
    queryParams: {
      handler() {
        if (this.queryParams.mailTemplateId == null) {
          this.closeModal();
          return;
        }
        if (this.queryParams.mailTemplateId !== this.id) {
          this.showModal(this.queryParams.mailTemplateId);
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid;
    if (this.queryParams.mailTemplateId != null) {
      this.showModal(this.queryParams.mailTemplateId);
    }
  },
  methods: {
    ...mapActions('mailTemplate', ['getMailTemplateById']),
    showModal(id) {
      this.id = id;
      this.loading = true;
      this.dialog.show = true;
      this.dialog.tab = 0;
      this.isNewMailTemplate = this.id == null || this.id === 0;
      if (!this.isNewMailTemplate) {
        this.refreshData();
      } else {
        this.mailTemplate = MailTemplate();
        this.error = null;
        this.loading = false;
      }
      this.pushState({
        mailTemplateId: id,
        tab: 0,
      });
    },
    closeModal() {
      this.dialog.show = false;
      this.mailTemplate = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.id = null;
      this.pushState({
        mailTemplateId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      this.getMailTemplateById(this.id)
          .then(mailTemplate => {
            $this.mailTemplate = mailTemplate;
            $this.error = null;
            $this.loading = false;
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
  }
};
</script>

<style scoped>

</style>
