<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-user"></i>
          {{ $t('tabs.mailTemplates.details') }}
        </h4>
      </b-col>
      <b-col cols="1" xl="1">
        <label class="invisible">{{ $t('columns.active') }}</label>
        <b-checkbox switch v-model="mailTemplate.active">{{ $t('columns.active') }}</b-checkbox>
      </b-col>


      <b-col cols="2" xl="2">
        <label>{{ $t('columns.org') }}</label>
        <organization-select-box v-model="mailTemplate.orgId"
        />

      </b-col>


      <b-col cols="3" xl="3">
        <label>{{ $t('columns.title') + ' (Subject)' }}</label>
        <b-form-input v-model="currentSubject"/>
      </b-col>

      <b-col cols="3" xl="3">
        <label>{{ $t('columns.mailEvent') }}</label>
        <v-select
            v-model="mailTemplate.mailEvent"
            :options="$store.getters['data/allMailEvents']"
        />
      </b-col>

      <b-col cols="3" xl="3">
        <label>{{ $t('columns.lang') }}</label>
        <v-select
            :clearable="false"
            v-model="selectedLanguage"
            :options="languageOptions"
            @input="updateLanguage"
        />
      </b-col>


    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col cols="12" class="mt-2">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-user"></i>
          {{ $t('tabs.mailTemplates.template') }}
        </h4>
      </b-col>
      <b-col cols="12" class="mb-2 mt-1">
        <b-form-group label-for="realEstate-name">
          <h5 style="color: dodgerblue">Placeholder Options</h5>
          <div class="mb-2">
            <span style="margin: 0 5px" v-for="placeholder in placeholders" :key="placeholder"
                  @click="insertPlaceholder(placeholder)"
            >
              {{ placeholder }}
            </span>
          </div>
          <quill-editor
              ref="quillEditor"
              spellcheck="false"
              class="realEstate-description  quill-editor-min-height"
              v-bind:value="currentBody"
              @change="onEditorChange"

          />

        </b-form-group>
      </b-col>

    </b-row>

    <div class="text-center mt-4" style="position: absolute;bottom: 10px; width: 97.5%">
      <b-button block size="md" variant="primary" @click="save">{{$t('operations.save')}}</b-button>

    </div>
  </div>
</template>

<script>
import MailTemplate from '@/model/mailTemplate/MailTemplate';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions, mapGetters } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox';
import CalendarPicker from '@/components/widget/picker/calendarPicker';
import mixinBase from '@/components/mixin/mixinBase';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default {
  name: 'MailTemplateEditDetails',
  components: {
    CalendarPicker,
    CountriesSelectBox,
    BusinessUnitSelectBox,
    OrganizationSelectBox,
  },
  mixins: [mixinPermissions, mixinNotifications, mixinBase],
  data: () => ({
    placeholders: ['#firstname#', '#lastname#', '#fullname#', '#email#', '#phone#', '#country#', '#state#', '#postcode#', '#address#',
      '#balance_total#', '#company#', '#platform#', '#link#', '#username#', '#password#', '#amount#', '#currency#'],
    mailTemplate: MailTemplate(),
    selectedLanguage: 'EN',
    languageOptions: [ 'EN', 'FR'],

  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newMailTemplate: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.mailTemplate = Object.assign(MailTemplate(), this.value);
      },
      immediate: true
    },
    'mailTemplate.language': {
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.selectedLanguage = newValue[0];
        }
      },
      immediate: true
    }
  },
  created() {
  },
  computed: {
    ...mapGetters('mailTemplate', ['']),
    currentSubject: {
      get() {
        return this.mailTemplate.subject[this.selectedLanguage] || '';
      },
      set(newSubject) {
        this.$set(this.mailTemplate.subject, this.selectedLanguage, newSubject);
      }
    },
    currentBody: {
      get() {
        return this.mailTemplate.body[this.selectedLanguage] || '';
      },
      set(newBody) {
        this.$set(this.mailTemplate.body, this.selectedLanguage, newBody);
      }
    }
  },
  methods: {
    ...mapActions('mailTemplate', ['updateMailTemplate', 'createMailTemplate']),
    insertPlaceholder(placeholder) {
      const quill = this.$refs.quillEditor.quill;
      const range = quill.getSelection(true);

      if (range) {
        quill.insertText(range.index, placeholder);
        quill.setSelection(range.index + placeholder.length);
      } else {
        quill.insertText(0, placeholder);
        quill.setSelection(placeholder.length);
      }
    },
    onEditorChange({ quill, html, text }) {
      if (this.mailTemplate.body[this.selectedLanguage] === html) {
        return;
      }
      this.mailTemplate.body[this.selectedLanguage] = html;
    },
    updateLanguage(newLang) {
      this.selectedLanguage = newLang;
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.deleteModal.id);
      this.$root.$emit('bv::hide::modal', this.newModal.id);
    },

    async addMailTemplate() {
      let $this = this;
      await this.createMailTemplate({
        mailTemplate: this.mailTemplate
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.addDataError', { system: $this.$t('menu.mailTemplates') }), $this.getErrorDescription(err));
          });
      this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.mailTemplates') }));
      this.$emit('input', this.mailTemplate);
      this.$emit('close');

    },
    async modifyMailTemplate() {
      let $this = this;

      await this.updateMailTemplate({
        mailTemplate: this.mailTemplate,
        id: this.mailTemplate.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.mailTemplates') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.mailTemplates') }));
      this.$emit('input', this.mailTemplate);
      this.$root.$emit('close');
      this.refreshTables();

    },
    deleteOpen(item, index, button) {
      this.deleteModal.title = `Row index: ${index}`;
      this.deleteModal.cpaId = item.id;
      this.$root.$emit('bv::show::modal', this.deleteModal.id, button);
    },
    openNewModal() {
      this.newCpa = {};
      this.$root.$emit('bv::show::modal', this.newModal.id);
    },
    async save() {
      if (this.mailTemplate.id == null) {
        await this.addMailTemplate();
      } else {
        await this.modifyMailTemplate();
      }
    }
  }
};
</script>

<style scoped>
.table-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.add-button {
  margin-left: 1rem;
}

.horizontal-list {
  display: flex;
  list-style: none;
  padding: 0;
}

.horizontal-list li {
  margin-right: 10px;
}

.dark-layout .realEstate-description {
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.quill-editor-min-height {
  min-height: 300px; /* Adjust the value as needed */
}
</style>
