export default function (other) {
  other = other || {};
  return {
    id: other.id || null,
    orgId: other.orgId || null,
    subject: other.subject || {},
    body: other.body || {},
    active: other.active || null,
    mailEvent: other.mailEvent || null,
    language: other.language || ['EN'],
  };
}
